<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b6">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
        <input type="text" value="" name="keywords" class="keyword" /><input type="image" src="/img/btn_search.gif" value="" alt="検索" name="" class="submit" />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH"><a href="http://ebiki.jp/">トップページ</a> &gt; <a href="/">絵引データベース</a> &gt; {{ title }}</div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder">
        <h2>作品連想検索結果</h2>
      </div>
      <!-- /H2 -->
      <!-- 2段組み -->
      <div class="pane-binder">
        <!-- 左側 -->
        <div class="left-2">
          <!-- 表示制御 -->
          <div class="controller">
            <!-- 検索ボタン -->
            <input type="image" src="/img/btn_search_red_s.gif" alt="検索" @click="getAssoc" />
            <!-- /検索ボタン -->
            <!-- ページ送り -->
            <div class="paginate">
              <input v-if="total && req_config['a-offset']" type="image" src="/img/btn_paginate_prev_on.gif" alt="前ページ" @click="prevList" />
              <img v-else src="/img/btn_paginate_prev_off.gif" alt="" />
              <input v-if="total > req_config['a-offset'] + req_config.narticles" type="image" src="/img/btn_paginate_next_on.gif" alt="次ページ" @click="nextList" />
              <img v-else src="/img/btn_paginate_next_off.gif" alt="" />
            </div>
            <!-- /ページ送り -->
            <!-- 表示件数 -->
            <ul class="limitWork">
              <li :class="[req_config.narticles == 10 ? 'selected' : '']"><a @click="req_config.narticles = 10">作品表示数 10</a></li>
              <li :class="[req_config.narticles == 20 ? 'selected' : '']"><a @click="req_config.narticles = 20">作品表示数 20</a></li>
              <li :class="[req_config.narticles == 50 ? 'selected' : '']"><a @click="req_config.narticles = 50">作品表示数 50</a></li>
              <li :class="[req_config.narticles == 100 ? 'selected' : '']"><a @click="req_config.narticles = 100">作品表示数 100</a></li>
            </ul>
            <!-- /表示件数 -->
            <!-- 総数 -->
            <p class="total">全{{ total }}件</p>
            <!-- /総数 -->
          </div>
          <!-- /表示制御 -->
          <!-- 一覧 -->
          <paginate v-if="total" name="works" :list="works" :per="req_config.narticles" :tag="'table'" :class="'base-skin'">
            <tr>
              <th scope="col" class="first"></th>
              <th scope="col" class="image">画像</th>
              <th scope="col">作品</th>
              <th scope="col">作者</th>
            </tr>
            <template v-for="work in paginated('works')">
              <tr :key="work.id">
                <td class="checkbox"><input type="checkbox" :value="work.id" v-model="req_works" /></td>
                <td><router-link :to="{ path: '/works/view/' + work.id}"><img :src="work.image" :alt="work.title" :title="work.title" width="70" /></router-link></td>
                <td><router-link :to="{ path: '/works/view/' + work.id}">{{ work.title }}</router-link></td>
                <td>{{ work.author }}</td>
              </tr>
            </template>
          </paginate>
          <template v-else>
            <table class="base-skin">
              <tr>
                <th scope="col" class="first"></th>
                <th scope="col" class="image">画像</th>
                <th scope="col">作品</th>
                <th scope="col">作者</th>
              </tr>
            </table>
          </template>
          <!-- 一覧 -->
          <!-- 表示制御 -->
          <div class="controller">
            <!-- 検索ボタン -->
            <input type="image" src="/img/btn_search_red_s.gif" alt="検索" @click="getAssoc" />
            <!-- /検索ボタン -->
            <!-- ページ送り -->
            <div class="paginate">
              <input v-if="total && req_config['a-offset']" type="image" src="/img/btn_paginate_prev_on.gif" alt="前ページ" @click="prevList" />
              <img v-else src="/img/btn_paginate_prev_off.gif" alt="" />
              <input v-if="total > req_config['a-offset'] + req_config.narticles" type="image" src="/img/btn_paginate_next_on.gif" alt="次ページ" @click="nextList" />
              <img v-else src="/img/btn_paginate_next_off.gif" alt="" />
            </div>
            <!-- /ページ送り -->
            <!-- 表示件数 -->
            <!-- JavaScriptにより、前出のプルダウンがコピーされます。 -->
            <!-- /表示件数 -->
            <!-- 総数 -->
            <p class="total">全{{ total }}件</p>
            <!-- /総数 -->
          </div>
          <!-- /表示制御 -->
          <!-- 自由入力欄 -->
          <div class="edit-box">
            <div class="frame"><textarea cols="60" rows="5" name="assoc_text" v-model="req_text"></textarea></div>
            <div><input type="image" src="/img/btn_search_red_l.gif" alt="検索" @click="getAssoc" /><a href="#" onclick="$('textarea').val('');return false"><img src="/img/btn_reset.gif" width="107" height="21" alt="クリア" /></a></div>
          </div>
          <!-- /自由入力欄 -->
        </div>
        <!-- /左側 -->
        <!-- 右側 -->
        <div class="right-2">
          <!-- 表示制御 -->
          <div class="controller">
            <!-- 検索ボタン -->
            <input type="image" src="/img/btn_search_red_s.gif" alt="検索" @click="getAssoc" />
            <!-- /検索ボタン -->
            <!-- 表示件数 -->
            <ul class="limitWord">
              <li :class="[req_config.nkeywords == 10 ? 'selected' : '']"><a @click="req_config.nkeywords = 10">ワード表示数 10</a></li>
              <li :class="[req_config.nkeywords == 20 ? 'selected' : '']"><a @click="req_config.nkeywords = 20">ワード表示数 20</a></li>
              <li :class="[req_config.nkeywords == 50 ? 'selected' : '']"><a @click="req_config.nkeywords = 50">ワード表示数 50</a></li>
              <li :class="[req_config.nkeywords == 100 ? 'selected' : '']"><a @click="req_config.nkeywords = 100">ワード表示数 100</a></li>
            </ul>
            <!-- /表示件数 -->
          </div>
          <!-- /表示制御 -->
          <!-- 関連ワード -->
          <dl class="related-word">
            <dt>関連ワード</dt>
            <dd>
              <ul>
                <li v-for="word in words" :key="word.id"><label><input type="checkbox" :value="word.id" v-model="req_words" /><span>{{ word.id }}</span></label></li>
              </ul>
            </dd>
          </dl>
          <!-- /関連ワード -->
          <!-- 表示制御 -->
          <div class="controller">
            <!-- 検索ボタン -->
            <input type="image" src="/img/btn_search_red_s.gif" alt="検索" @click="getAssoc" />
            <!-- /検索ボタン -->
            <!-- 表示件数 -->
            <!-- JavaScriptにより、前出のプルダウンがコピーされます。 -->
            <!-- /表示件数 -->
          </div>
          <!-- /表示制御 -->
        </div>
        <!-- /右側 -->
      </div>
      <!-- /2段組み -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from '@/ajax/Backend'
const GETAssocWork = Backend.get('work')

// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

import $ from 'jquery'
import ebikiMixin from '@/mixins/ebikiMixin.js'

export default {
  name: 'assoc',
  mixins: [ ebikiMixin ],
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    manifest_id:  { type: Number, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  data: function () {
    return {
      req_config: { narticles: 20, nkeywords: 20, 'a-offset': 0 },
      paginate: ['works'],
      works: [],
      words: [],
      req_text: '',
      req_works: [],
      req_words: [],
      total: 0
    };
  },
  watch: {
    $route: {
      handler: function() {
        if (this.$route.query.assoc_text) {
          this.req_text = this.$route.query.assoc_text
          this.getAssoc()
        }        
      },
      immediate: true,
    },
    req_config: {
      handler: function() {
        this.getAssoc()
      },
      deep: true
    },
    'req_config.narticles': function() {
      if (this.total) {
        $('.select-work-collapsed p').text('作品表示数 '+this.req_config.narticles)
      }
      this.req_config['a-offset'] = 0
    },
    'req_config.nkeywords': function() {
      if (this.total) {
        $('.select-word-collapsed p').text('ワード表示数 '+this.req_config.nkeywords)
      }
      this.req_config['a-offset'] = 0
    }
  },
  methods: {
    prevList: function() {
      this.req_config['a-offset'] = this.req_config['a-offset'] - this.req_config.narticles
      if (this.req_config['a-offset'] < 0) {
        this.req_config['a-offset'] = 0
      }
    },
    nextList: function() {
      this.req_config['a-offset'] = this.req_config['a-offset'] + this.req_config.narticles
    },
    // GETAssocへの検索クエリ送信
    getAssoc: function() {
      return GETAssocWork.assoc(this.req_config, this.req_text, this.req_works, this.req_words)
        .then(response => {
          if (!response.gss.result.articles) {
            this.total = 0;
          } else {
            this.total = response.gss.result.articles.$.total
          }
          if (this.total) {
            // 作品情報の取得
            let articles = response.gss.result.articles.cls.article
            if (!Array.isArray(articles)) {
              articles = [articles]
            }
            this.works = []
            articles.forEach((_, i) => {
              let work = {}
              work.id = articles[i].$.name
              work.title = articles[i].$.title
              work.author = articles[i].$.author
              //work.image = articles[i].$.img
              work.image = 'https://eiichi.shibusawa.or.jp/api/images/iiif/'+articles[i].$.name+'/full/!106,/0/default.jpg'
              this.works.push(work)
            });
            // 関連ワード情報の取得
            let words = response.gss.result.keywords.cls.keyword
            if (!Array.isArray(words)) {
              words = [words]
            }
            this.words = []
            words.forEach((_, i) => {
              let word = {}
              word.id = words[i].$.name
              this.words.push(word)
            });
          }
        })
        .catch(err => console.log(err))
    },
  },
  mounted: async function() {
    // 昔からあったjQuery
    $(function() {
      $('#CONTENT-BODY div.controller ul.limitWork').pretendSelect('select-work');
      $('#CONTENT-BODY div.controller ul.limitWord').pretendSelect('select-word');
    });
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.meta_description },
        { name: 'keywords', content: this.meta_keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.meta_description },
      ]
    }
  }
}
</script>
